<template>
  <div class="input">
    <h2 class="input__title">Comments</h2>
    <div class="input__wrap">
      <h3 class="input__subtitle">Comments Default</h3>
      <Comments
        :comments="commentsList"
        @createAction="createAction"
        @updateAction="updateAction"
        @deleteAction="deleteAction"
        @checkValidationData="checkValidationData"
      />
    </div>
    <div class="input__wrap">
      <h3 class="input__subtitle">Comments Props</h3>
      <ul class="input__list"></ul>
    </div>
  </div>
</template>

<script>
import Comments from "@/modules/UiKit/components/Comments/Comments";
export default {
  name: "CommentsPage",
  mixins: [],
  props: [],
  components: { Comments },
  data() {
    return {
      commentsList: [
        {
          author: {
            avatar: "/public/1632883420_pool_avatar.png",
            full_name: "Кузнецова Элисса",
            id: 501,
            roles: [
              {
                laravel_through_key: 501,
                name: "Главный паникер"
              }
            ]
          },
          commentable_id: 417,
          content: "Чего еще не хватает, кроме координации",
          count_comments: 0,
          count_likes: 0,
          datetime_of_creation: "2021-12-15 11:09:18",
          id: 729,
          is_changed: false,
          is_deleted: false,
          likes: [],
          parent_id: null,
          replies: [],
          user_id: 18
        },
        {
          author: {
            avatar: "/public/1630640613_pool_avatar.png",
            full_name: "Сутягина Анна",
            id: 68,
            roles: [
              {
                laravel_through_key: 68,
                name: "Бэкенд разработчик"
              }
            ]
          },
          commentable_id: 417,
          content:
            "Изучила дашборды тестировщиков. Метрика: дашборд, в котором видна загруженность аналитиков: текущая и потенциальная + кол-во/сложность/кач-во? задач проходящая через каждого аналитика? Чтобы планировать найм и видеть хотя бы грубо эффективность работы.",
          count_comments: 0,
          count_likes: 0,
          datetime_of_creation: "2021-12-15 11:09:18",
          id: 729,
          is_changed: false,
          is_deleted: false,
          likes: [],
          parent_id: null,
          replies: [],
          user_id: 18
        },
        {
          author: {
            avatar: "/public/1632883420_pool_avatar.png",
            full_name: "Кузнецова Элисса",
            id: 501,
            roles: [
              {
                laravel_through_key: 501,
                name: "Главный паникер"
              }
            ]
          },
          commentable_id: 417,
          content: "Подумать, добавить в задачи обучение руководителей",
          count_comments: 0,
          count_likes: 0,
          datetime_of_creation: "2022-01-11 11:14:25",
          id: 825,
          is_changed: false,
          is_deleted: false,
          likes: [],
          parent_id: null,
          user_id: 45,
          replies: [
            {
              author: {
                avatar: "/public/1622025811_pool_avatar.png",
                full_name: "Галанова Марина",
                id: 329,
                roles: [
                  {
                    laravel_through_key: 329,
                    name: "Главный антипаникер"
                  }
                ]
              },
              commentable_id: 984,
              content: "Марина, балбла",
              count_comments: 0,
              count_likes: 0,
              datetime_of_creation: "2022-02-03 09:31:28",
              id: 2264,
              is_changed: false,
              is_deleted: false,
              likes: [],
              parent_id: 2261,
              replies: [],
              user_id: 329
            },
            {
              author: {
                avatar: "/public/1622025811_pool_avatar.png",
                full_name: "Галанова Марина",
                id: 329,
                roles: [
                  {
                    laravel_through_key: 329,
                    name: "Главный антипаникер"
                  }
                ]
              },
              commentable_id: 984,
              content: "Марина, блабла",
              count_comments: 0,
              count_likes: 0,
              datetime_of_creation: "2022-02-03 09:31:35",
              id: 2265,
              is_changed: false,
              is_deleted: false,
              likes: [],
              parent_id: 2261,
              replies: [],
              user_id: 329
            }
          ]
        }
      ]
    };
  },
  mounted() {},
  methods: {
    createAction(body) {
      console.log(body);
    },
    updateAction(body) {
      console.log(body);
    },
    deleteAction(id) {
      console.log(id);
    },
    checkValidationData(body) {
      console.log(body);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors.scss";

.input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;

    &_textarea {
      margin-top: 48px;
    }
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__content {
    margin: 24px 0 48px;
  }

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
